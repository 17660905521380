@font-face {
	font-family: "Aeonik";
	font-style: normal;
	font-weight: 300;
	src: local("Aeonik Light"), local("Aeonik-Light"),
		url("./assets/fonts/Aeonik-Light.otf");
}

@font-face {
	font-family: "Aeonik";
	font-style: normal;
	font-weight: 400;
	src: local("Aeonik Regular"), local("Aeonik-Regular"),
		url("./assets/fonts/Aeonik-Regular.otf");
}

@font-face {
	font-family: "Aeonik";
	font-style: normal;
	font-weight: 500;
	src: local("Aeonik Medium"), local("Aeonik-Medium"),
		url("./assets/fonts/Aeonik-Medium.otf");
}

@font-face {
	font-family: "Aeonik";
	font-style: normal;
	font-weight: 700;
	src: local("Aeonik Bold"), local("Aeonik-Bold"),
		url("./assets/fonts/Aeonik-Bold.otf");
}

:root {
	--main-bkgd-color: #333333;
	--section-bkgd-color: #d9d9d9;
	--primary-color: #333333;
	--secondary-color: #ffffff;
	--tertiary-color: #d9d9d9;
	--card-bkgd-color: rgba(51, 51, 51, 0.9);
	--aeonik-font: "Aeonik", sans-serif;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	background-color: var(--main-bkgd-color);
}

.root {
	width: 100%;
	height: 100%;
}
