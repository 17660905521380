@font-face {
  font-family: Aeonik;
  font-style: normal;
  font-weight: 300;
  src: local(Aeonik Light), local(Aeonik-Light), url("Aeonik-Light.42157620.otf");
}

@font-face {
  font-family: Aeonik;
  font-style: normal;
  font-weight: 400;
  src: local(Aeonik Regular), local(Aeonik-Regular), url("Aeonik-Regular.72164c3c.otf");
}

@font-face {
  font-family: Aeonik;
  font-style: normal;
  font-weight: 500;
  src: local(Aeonik Medium), local(Aeonik-Medium), url("Aeonik-Medium.c3fbe94d.otf");
}

@font-face {
  font-family: Aeonik;
  font-style: normal;
  font-weight: 700;
  src: local(Aeonik Bold), local(Aeonik-Bold), url("Aeonik-Bold.a3a3567d.otf");
}

:root {
  --main-bkgd-color: #333;
  --section-bkgd-color: #d9d9d9;
  --primary-color: #333;
  --secondary-color: #fff;
  --tertiary-color: #d9d9d9;
  --card-bkgd-color: #333333e6;
  --aeonik-font: "Aeonik", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  background-color: var(--main-bkgd-color);
  margin: 0 auto;
  overflow: hidden;
}

.root {
  width: 100%;
  height: 100%;
}

/*# sourceMappingURL=index.dd6399af.css.map */
